




















































import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import apiService from "../services/apiService";
import { Guid } from "guid-typescript";
import Validation from "@/mixins/validation";
import UserResetPasswordModel from "@/models/UserResetPasswordModel";

@Component
export default class PasswordReset extends Mixins(Validation) {
  @Prop(String) readonly id!: string;

  private valid: boolean = false;
  private showPassword: boolean = false;
  private showConfirmPassword: boolean = false;
  private snackbar: boolean = false;

  private vertical: boolean = true;
  private y: string = "top";
  private x: string = "right";

  private model: UserResetPasswordModel = new UserResetPasswordModel();

  successMesssage = "Password has been successfully updated.";

  get passwordsMatch() {
    return (
      this.model.password === this.model.confirmPassword ||
      "Password must match"
    );
  }

  private login() {
    this.$router.push({ name: "login" });
  }

  private async updatePassword() {
    await apiService.postResetPassword(
      Guid.parse(this.id),
      this.model.password
    );
    this.snackbar = true;
    this.model.password = "";
    this.model.confirmPassword = "";
  }
}
