import { Guid } from "guid-typescript";
export default class UserResetPasswordModel {
  public password: string = "";
  public confirmPassword: string = "";

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
